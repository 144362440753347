import type { ReactNode } from "react";
import React from "react";

/**
 * Joins an array with a node
 */
export function jsxJoin(
  array: ReactNode[],
  separator: ReactNode,
  ignoreEmpty = true,
) {
  if (ignoreEmpty) {
    array = array.filter((item) => item !== null && item !== undefined);
  }

  return array.length > 0
    ? // eslint-disable-next-line unicorn/no-array-reduce -- need this here
      array.reduce((result, item) => (
        <>
          {result}
          {separator}
          {item}
        </>
      ))
    : null;
}
